<template>
  <div id="incomingInspectionReport">
    <el-dialog
      :title="incomingInspectionReportFormTitle"
      width="1200px"
      :visible.sync="incomingInspectionReportDialogVisible"
      :close-on-click-modal="false"
      @close="incomingInspectionReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="incomingInspectionReportFormRef"
        :model="incomingInspectionReportForm"
        :rules="incomingInspectionReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="incomingInspectionReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供方" prop="supplierName">
              <el-input
                v-model="incomingInspectionReportForm.supplierName"
                placeholder="请输入供方"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号规格" prop="spec">
              <el-input
                v-model="incomingInspectionReportForm.spec"
                placeholder="请输入型号规格"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-select
                v-model="incomingInspectionReportForm.productName"
                placeholder="请选择产品名称"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
                @change="productChange"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.type"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="进货日期" prop="purchaseDate">
              <el-date-picker
                v-model="incomingInspectionReportForm.purchaseDate"
                placeholder="请选择进货日期"
                value-format="yyyy-MM-dd"
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="进货数量" prop="purchaseQuantity">
              <el-input
                v-model="incomingInspectionReportForm.purchaseQuantity"
                placeholder="请输入进货数量"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验数量" prop="inspectionQuantity">
              <el-input
                v-model="incomingInspectionReportForm.inspectionQuantity"
                placeholder="请输入检验数量"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原料批号" prop="materialBatchNo">
              <el-input
                v-model="incomingInspectionReportForm.materialBatchNo"
                placeholder="请输入原料批号"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验依据" prop="basis">
              <el-input
                v-model="incomingInspectionReportForm.basis"
                readonly
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="抽样方案" prop="samplingPlan">
              <el-input
                v-model="incomingInspectionReportForm.samplingPlan"
                readonly
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="verificationItem"
              title="验证项目"
              width="170"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="standard"
              title="标准要求"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="result"
              title="检验结果"
              width="120"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: 1, label: '合格' },
                  { value: 2, label: '不合格' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="验证结论" prop="conclusion">
              <el-radio-group
                v-model="incomingInspectionReportForm.conclusion"
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="2">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="不合格品处置" prop="dealWay">
              <el-radio-group
                v-model="incomingInspectionReportForm.dealWay"
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              >
                <el-radio :label="1">
                  退货
                </el-radio>
                <el-radio :label="2">
                  拣用
                </el-radio>
                <el-radio :label="3">
                  让步接收
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="incomingInspectionReportForm.remark"
                placeholder="请输入备注"
                clearable
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="incomingInspectionReportForm.reviewResult"
                :disabled="incomingInspectionReportFormTitle !== '复核进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="incomingInspectionReportForm.approvalResult"
                :disabled="incomingInspectionReportFormTitle !== '批准进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检验记录" prop="testRecord">
              <el-input
                v-model="incomingInspectionReportForm.testRecord"
                placeholder="请输入检验记录"
                type="textarea"
                :rows="8"
                :disabled="incomingInspectionReportFormTitle !== '新增进货检验报告'
                  && incomingInspectionReportFormTitle !== '修改进货检验报告'
                  && incomingInspectionReportFormTitle !== '进货检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="incomingInspectionReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="incomingInspectionReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="incomingInspectionReportFormTitle === '进货检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-select v-model="searchForm.productName" placeholder="请选择产品名称" clearable>
          <el-option
            v-for="item in productList"
            :key="item.type"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['INCOMING_INSPECTION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="incomingInspectionReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="supplierName" label="供方" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column label="进货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseDate">{{ scope.row.purchaseDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="purchaseQuantity" label="进货数量" />
      <el-table-column prop="inspectionQuantity" label="检验数量" />
      <el-table-column prop="materialBatchNo" label="原料批号" />
      <el-table-column prop="basis" label="检验依据" />
      <el-table-column prop="samplingPlan" label="抽样方案" />
      <el-table-column label="验证结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 1">合格</span>
          <span v-if="scope.row.conclusion === 2">不合格</span>
        </template>
      </el-table-column>
      <el-table-column label="不合格品处置">
        <template slot-scope="scope">
          <span v-if="scope.row.dealWay === 1">退货</span>
          <span v-if="scope.row.dealWay === 2">拣用</span>
          <span v-if="scope.row.dealWay === 3">让步接收</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="testRecord" label="检验记录" /> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['INCOMING_INSPECTION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['INCOMING_INSPECTION_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['INCOMING_INSPECTION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['INCOMING_INSPECTION_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="incomingInspectionReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addIncomingInspectionReport,
  deleteIncomingInspectionReport,
  updateIncomingInspectionReport,
  selectIncomingInspectionReportInfo,
  selectIncomingInspectionReportList,
  reviewIncomingInspectionReport,
  approvalIncomingInspectionReport
} from '@/api/quality/incomingInspectionReport'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      incomingInspectionReportDialogVisible: false,
      incomingInspectionReportFormTitle: '',
      incomingInspectionReportForm: {
        id: '',
        formNo: '',
        supplierName: '',
        spec: '',
        productName: '',
        purchaseDate: '',
        purchaseQuantity: '',
        inspectionQuantity: '',
        materialBatchNo: '',
        basis: '',
        samplingPlan: '',
        conclusion: '',
        dealWay: '',
        remark: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        type: '',
        testRecord: '',
        detailJson: '',
        taskId: ''
      },
      incomingInspectionReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      incomingInspectionReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      },
      productList: [
        { type: 1, name: '中盒' },
        { type: 2, name: '泡棉' },
        { type: 3, name: '双极连线' },
        { type: 4, name: '清洁片' },
        { type: 5, name: '三件套彩盒' },
        { type: 6, name: '脚控线' },
        { type: 7, name: '铝箔袋' },
        { type: 8, name: '伸缩刀' },
        { type: 9, name: '二维码' },
        { type: 10, name: '插头线' },
        { type: 11, name: '刀头' },
        { type: 12, name: '线路板组件' },
        { type: 13, name: '外箱' },
        { type: 14, name: '手术电极塑料件' },
        { type: 15, name: 'PE袋' },
        { type: 16, name: '纸塑袋' },
        { type: 17, name: '负极板' },
        { type: 18, name: '合格证' },
        { type: 19, name: '说明书' },
        { type: 20, name: '镊子线' },
        { type: 21, name: '保护盒' },
        { type: 22, name: '铆钉' },
        { type: 23, name: '彩页' },
        { type: 24, name: '热塑管' },
        { type: 25, name: '平面卷袋' },
        { type: 26, name: '塑料管' },
        { type: 27, name: '插头线-03' }
      ],
      detailList: []
    }
  },
  created () {
    selectIncomingInspectionReportList(this.searchForm).then(res => {
      this.incomingInspectionReportPage = res
    })
  },
  methods: {
    incomingInspectionReportDialogClose () {
      this.$refs.incomingInspectionReportFormRef.resetFields()
      this.detailList = []
    },
    incomingInspectionReportFormSubmit () {
      if (this.incomingInspectionReportFormTitle === '进货检验报告详情') {
        this.incomingInspectionReportDialogVisible = false
        return
      }
      this.$refs.incomingInspectionReportFormRef.validate(async valid => {
        if (valid) {
          if (this.incomingInspectionReportFormTitle === '新增进货检验报告') {
            this.incomingInspectionReportForm.id = ''
            this.incomingInspectionReportForm.status = 1
            this.incomingInspectionReportForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addIncomingInspectionReport(this.incomingInspectionReportForm)
          } else if (this.incomingInspectionReportFormTitle === '修改进货检验报告') {
            this.incomingInspectionReportForm.detailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateIncomingInspectionReport(this.incomingInspectionReportForm)
          } else if (this.incomingInspectionReportFormTitle === '复核进货检验报告') {
            this.incomingInspectionReportForm.status = 2
            await reviewIncomingInspectionReport(this.incomingInspectionReportForm)
          } else if (this.incomingInspectionReportFormTitle === '批准进货检验报告') {
            this.incomingInspectionReportForm.status = 3
            await approvalIncomingInspectionReport(this.incomingInspectionReportForm)
          }
          this.incomingInspectionReportPage = await selectIncomingInspectionReportList(this.searchForm)
          this.incomingInspectionReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.incomingInspectionReportFormTitle = '新增进货检验报告'
      this.incomingInspectionReportDialogVisible = true
      this.incomingInspectionReportForm.formNo = 'JJ' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteIncomingInspectionReport(row.id)
        if (this.incomingInspectionReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.incomingInspectionReportPage = await selectIncomingInspectionReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.incomingInspectionReportFormTitle = '修改进货检验报告'
      this.incomingInspectionReportDialogVisible = true
      this.selectIncomingInspectionReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.incomingInspectionReportFormTitle = '复核进货检验报告'
      this.incomingInspectionReportDialogVisible = true
      this.selectIncomingInspectionReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.incomingInspectionReportFormTitle = '批准进货检验报告'
      this.incomingInspectionReportDialogVisible = true
      this.selectIncomingInspectionReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.incomingInspectionReportFormTitle = '进货检验报告详情'
      this.incomingInspectionReportDialogVisible = true
      this.selectIncomingInspectionReportInfoById(row.id)
    },
    selectIncomingInspectionReportInfoById (id) {
      selectIncomingInspectionReportInfo(id).then(res => {
        this.incomingInspectionReportForm.id = res.id
        this.incomingInspectionReportForm.formNo = res.formNo
        this.incomingInspectionReportForm.supplierName = res.supplierName
        this.incomingInspectionReportForm.spec = res.spec
        this.incomingInspectionReportForm.productName = res.productName
        this.incomingInspectionReportForm.purchaseDate = res.purchaseDate
        this.incomingInspectionReportForm.purchaseQuantity = res.purchaseQuantity
        this.incomingInspectionReportForm.inspectionQuantity = res.inspectionQuantity
        this.incomingInspectionReportForm.materialBatchNo = res.materialBatchNo
        this.incomingInspectionReportForm.basis = res.basis
        this.incomingInspectionReportForm.samplingPlan = res.samplingPlan
        this.incomingInspectionReportForm.conclusion = res.conclusion
        this.incomingInspectionReportForm.dealWay = res.dealWay
        this.incomingInspectionReportForm.remark = res.remark
        this.incomingInspectionReportForm.reviewResult = res.reviewResult
        this.incomingInspectionReportForm.approvalResult = res.approvalResult
        this.incomingInspectionReportForm.status = res.status
        this.incomingInspectionReportForm.type = res.type
        this.incomingInspectionReportForm.testRecord = res.schedule.testRecord
        this.incomingInspectionReportForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectIncomingInspectionReportList(this.searchForm).then(res => {
        this.incomingInspectionReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectIncomingInspectionReportList(this.searchForm).then(res => {
        this.incomingInspectionReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectIncomingInspectionReportList(this.searchForm).then(res => {
        this.incomingInspectionReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['INCOMING_INSPECTION_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['INCOMING_INSPECTION_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    productChange (value) {
      if (value === '中盒') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 1
        this.detailList = [
          { verificationItem: '材料', standard: '面）150g白色纸板；外层美卡；（中）150g高强瓦楞纸—双瓦；（内）150g国产C级板牛皮纸', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '泡棉') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 2
        this.detailList = [
          { verificationItem: '材料', standard: '白色海绵', result: '' },
          { verificationItem: '外观', standard: '表面应干净整洁，无杂质', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' }
        ]
      } else if (value === '双极连线') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 3
        this.detailList = [
          { verificationItem: '材料', standard: '铜丝TR-0.14；电缆线外护套：PVC', result: '' },
          { verificationItem: '外观', standard: '表面应无明显的油迹等污点，护套均匀不得破损、刮痕等缺陷；颜色：淡兰色', result: '' },
          { verificationItem: '尺寸', standard: '插头尺寸应符合图纸要求；插头线长度测量：从端子头部开始至电缆线尾部；插头线尾部用铜脚扣铆上，插头为圆形；插头线长度公差：±50mm', result: '' },
          { verificationItem: '芯数、外径及铜丝股数', standard: '二芯，各芯线外径为φ2.0±0.05，外径φ4.0±0.1；内芯铜丝为Ф0.14±0.005×10股', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      } else if (value === '清洁片') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 4
        this.detailList = [
          { verificationItem: '外观', standard: '表面应干净整洁，无杂质', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' }
        ]
      } else if (value === '三件套彩盒') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 5
        this.detailList = [
          { verificationItem: '材料', standard: '白色抹光纸板', result: '' },
          { verificationItem: '印刷质量', standard: '版面应正确，不得差错', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' }
        ]
      } else if (value === '脚控线') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 6
        this.detailList = [
          { verificationItem: '材料', standard: '铜丝TR-0.1；电缆线外护套：PVC；电缆线内护套：PE-H-18D075；波音端子：φ6.3', result: '' },
          { verificationItem: '外观', standard: '表面应无明显的油迹等污点，护套均匀不得破损、刮痕等缺陷；颜色：淡兰色', result: '' },
          { verificationItem: '尺寸', standard: '插头及波音端子尺寸应符合图纸要求；插头线长度测量：从端子头部开始至电缆线尾部；插头线尾部外护套剥离长度：58±2mm；插头线长度公差：±50mm', result: '' },
          { verificationItem: '芯数、外径及铜丝股数', standard: '一芯，各芯线外径为φ1.0±0.05，外径φ3.25±0.1；内芯铜丝为Ф0.1×10股', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      } else if (value === '铝箔袋') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 7
        this.detailList = [
          { verificationItem: '材料', standard: '铝箔，银白色', result: '' },
          { verificationItem: '外观', standard: '表面应洁净平整，无杂色、污点、皱痕等', result: '' },
          { verificationItem: '尺寸', standard: '应符合规格要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '伸缩刀') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 8
        this.detailList = [
          { verificationItem: '材料', standard: '铜丝TR-0.1；电缆线外护套：PVC', result: '' },
          { verificationItem: '外观', standard: '表面应无明显的油迹，无破损，颜色浅兰色', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求；线长3米', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      } else if (value === '二维码') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 9
        this.detailList = [
          { verificationItem: '外观', standard: '干净整洁，无皱痕，无破损', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '插头线') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 10
        this.detailList = [
          { verificationItem: '材料', standard: '铜丝TR-0.1；电缆线外护套：PVC；电缆线内护套：PE-H-18D075；莲花端子：黄铜；插头塑料：PVC', result: '' },
          { verificationItem: '外观', standard: '表面应无明显的油迹等污点，护套均匀不得破损、刮痕等缺陷；插头三个莲花端子应整齐、无变形。颜色：深兰色', result: '' },
          { verificationItem: '尺寸', standard: '插头及莲花端子尺寸应符合图纸要求；插头线长度测量：从端子头部开始至电缆线尾部；插头线尾部外护套剥离长度：20±2mm；插头线长度公差：±50mm', result: '' },
          { verificationItem: '芯数、外径及铜丝股数', standard: '三芯，各芯线外径为φ1.0±0.05，外径φ3.1.0±0.1；铜丝为Ф0.1×10股（每根电线随机抽检2根铜丝）', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      } else if (value === '刀头') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 11
        this.detailList = [
          { verificationItem: '材料', standard: '0Cr18Ni9', result: '' },
          { verificationItem: '外观', standard: '表面光滑、光亮（按封样检验）无毛刺；无裂痕；边缘无缺口；两边形状对称；表面无油污', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸要求', result: '' },
          { verificationItem: '硬度', standard: '刀头头部弯曲30度时,测力应10-20N', result: '' }
        ]
      } else if (value === '线路板组件') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 12
        this.detailList = [
          { verificationItem: '外观', standard: '线路板表面镀镍处理、光亮、无破损，不允许有氧化脱落现象。表面无灰尘。卡口无毛刺；弹片表面光亮，无毛刺，无锈斑；PE管表面应光滑无缩孔、无破损现象、无异物，颜色符合要求', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' },
          { verificationItem: '物理性能', standard: '布线应正确、铜膜线之间、焊盘之间无误通及误断现象', result: '' },
          { verificationItem: '材料', standard: '卡口黄铜；弹片不锈钢SUS301', result: '' },
          { verificationItem: '压弹力', standard: '弹片5～6N', result: '' },
          { verificationItem: '插取力', standard: '用刀头插取，其插取力应为8-15N', result: '' },
          { verificationItem: '耐高压', standard: 'PE管能承受50Hz、3000V正弦波电压试，历时1min无击穿和闪络现象。（按实际使用要求进行热缩后测试）', result: '' }
        ]
      } else if (value === '外箱') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 13
        this.detailList = [
          { verificationItem: '材料', standard: '（面）200gA级板牛皮纸；外层美卡；（中）150g高强瓦楞纸—双瓦；（内）150g国产C级板牛皮纸', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确、清晰，不得有差错', result: '' }
        ]
      } else if (value === '手术电极塑料件') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 14
        this.detailList = [
          { verificationItem: '材料', standard: 'ABS757无毒塑料', result: '' },
          { verificationItem: '外观', standard: '表面及各配合部位应无毛刺、无飞边，注塑件成形应完整；无缩影。表面应无油污、无污染、无发丝、无昆虫；颜色：上下壳为瓷白色；按钮为黄色和深兰色。同批颜色不得有色差，不得有混色', result: '' },
          { verificationItem: '尺寸', standard: '尺寸应符合图纸要求', result: '' }
        ]
      } else if (value === 'PE袋') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 15
        this.detailList = [
          { verificationItem: '材料', standard: '高压筒料透明塑料薄膜LDPE', result: '' },
          { verificationItem: '外观', standard: '薄膜表面应洁净，无杂质、气泡、油迹等；薄膜手感光滑，开口好易撕开', result: '' },
          { verificationItem: '尺寸', standard: '应符合规格要求', result: '' }
        ]
      } else if (value === '纸塑袋') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 16
        this.detailList = [
          { verificationItem: '材料', standard: '采用60克涂胶纸，薄膜采用白膜', result: '' },
          { verificationItem: '外观', standard: '白膜表面光亮、无皱痕。表面不起泡，纸层不破损', result: '' },
          { verificationItem: '尺寸', standard: '涂胶纸厚度为0.085mm、白膜厚度为0.05mm、厚度公差±0.01mm。外形尺寸应符合图纸要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确、符合设计要求，不得有差错，版面文字清晰', result: '' },
          { verificationItem: '封口宽度', standard: '≥10MM', result: '' },
          { verificationItem: '质量', standard: '涂胶纸和塑料膜在撕开时不得粘连', result: '' }
        ]
      } else if (value === '负极板') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 17
        this.detailList = [
          { verificationItem: '持粘性', standard: '能承受至少2N的拉力且持续10S不脱落', result: '' }
        ]
      } else if (value === '合格证') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 18
        this.detailList = [
          { verificationItem: '外观', standard: '干净整洁，无皱痕，无破损', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '说明书') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 19
        this.detailList = [
          { verificationItem: '外观', standard: '干净整洁，无皱痕，无破损', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '镊子线') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 20
        this.detailList = [
          { verificationItem: '外观', standard: '表面应无明显的油迹等污点，护套均匀不得破损、刮痕等缺陷；颜色：镀镍灰色', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      } else if (value === '保护盒') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 21
        this.detailList = [
          { verificationItem: '外观', standard: '表面及各配合部位应无毛刺、无飞边，注塑件成型应完整，无缩影。表面应无油污、无污染、无发丝、无昆虫，颜色：透明无色，同批颜色不得有色差，有混色', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' },
          { verificationItem: '材料', standard: '无毒塑料，透明无色', result: '' }
        ]
      } else if (value === '铆钉') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 22
        this.detailList = [
          { verificationItem: '外观', standard: '表面光滑、光亮；边缘无缺口；表面无油污', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸要求', result: '' }
        ]
      } else if (value === '彩页') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 23
        this.detailList = [
          { verificationItem: '外观', standard: '干净整洁，无皱痕，无破损', result: '' },
          { verificationItem: '尺寸', standard: '应符合设计要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确，清晰，不得有差错', result: '' }
        ]
      } else if (value === '热塑管') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 24
        this.detailList = [
          { verificationItem: '材料', standard: 'PE', result: '' },
          { verificationItem: '外观颜色', standard: '表面应光滑无缩孔、无破损现象、无异物；颜色符合要求', result: '' },
          { verificationItem: '尺寸', standard: '外型尺寸、厚度应符合规定要求', result: '' },
          { verificationItem: '收缩性', standard: '≥50%（100℃沸水）', result: '' }
        ]
      } else if (value === '平面卷袋') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 25
        this.detailList = [
          { verificationItem: '材料', standard: '采用60克涂胶纸，薄膜采用白膜', result: '' },
          { verificationItem: '外观', standard: '白膜表面光亮、无皱痕。表面不得起泡，纸层不得破损', result: '' },
          { verificationItem: '尺寸', standard: '涂胶纸厚度为0.085mm、白膜厚度为0.05mm、厚度公差±0.01mm。外形尺寸应符合图纸要求', result: '' },
          { verificationItem: '印刷质量', standard: '版面文字印刷应正确、符合设计要求，不得有差错。版面文字清晰', result: '' },
          { verificationItem: '封口宽度', standard: '≥10mm', result: '' }
        ]
      } else if (value === '塑料管') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 25
        this.detailList = [
          { verificationItem: '外观', standard: '表面应干净整洁，无杂质', result: '' },
          { verificationItem: '尺寸', standard: '应符合图纸设计要求', result: '' }
        ]
      } else if (value === '插头线-03') {
        this.incomingInspectionReportForm.basis = '进货检验操作规程'
        this.incomingInspectionReportForm.samplingPlan = '检验水平：S-3级；接收质量限：AQL=2.5'
        this.incomingInspectionReportForm.type = 10
        this.detailList = [
          { verificationItem: '材料', standard: '铜丝TR-0.1；电缆线外护套：PVC', result: '' },
          { verificationItem: '外观', standard: '表面应无明显的油迹等污点，护套均匀不得破损、刮痕等缺陷；颜色：深兰色', result: '' },
          { verificationItem: '尺寸', standard: '插头尺寸应符合图纸要求插头线长度测量：从端子头部开始至电缆线尾部波音端子：φ6.3插头线长度公差：±50mm', result: '' },
          { verificationItem: '芯数、外径及铜丝股数', standard: '二芯，各芯线外径为φφ1.0±0.05，外径φ3.0±0.1；内芯铜丝为Ф0.1±0.005×10股', result: '' },
          { verificationItem: '低压测试', standard: '低压电路测试应无短路、断路现象', result: '' }
        ]
      }else {
        this.incomingInspectionReportForm.basis = ''
        this.incomingInspectionReportForm.samplingPlan = ''
        this.incomingInspectionReportForm.type = ''
        this.detailList = []
      }
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `进货检验报告${this.incomingInspectionReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
